<template>
	<div class="classroom-details row"  v-if="classroom">
		<div class="column custom-scrollbar">
			<div class="classroom-details-container">
				<div class="row">
					<span class="legend" v-if="!editMode">{{name}}</span>
					<template v-else >
						<input type="text" class="classroom-name" v-model="name" :placeholder="$t('titlePlaceHolder')">
					</template>
					<button v-if="!editMode" class="btn secondary" @click="changeEditMode">{{$t('edit')}}</button>
					<template v-else>
						<button class="btn secondary" @click="changeEditMode">{{$t('cancel')}}</button>
						<button class="btn primary" @click="updateClassroom">{{$t('saveChangesTag')}}</button>
					</template>
				</div>
				<div class="row">
					<div v-if="!editMode">
						<span >{{$t('classPeriod')}}</span> 
						<span>{{timePeriod}}</span>
					</div>
					<template v-else >
						<div class="row editable-dates">
							<span >{{$t('classPeriod')}}</span> 
							<datepicker v-on:selected="updateStartDate" v-model="StartDate"></datepicker>
							<span> - </span>
							<datepicker v-on:selected="updateEndDate" v-model="EndDate"></datepicker>
						</div>
					</template>
					<div class="row">
						<span>{{$t('teacher')}}</span> <span>{{teacherName}}</span>
					</div>
				</div>
				<div class="row">
					<div>
						<span>{{$t('studentsNumber')}}</span> 
						<span v-if="classroom">{{`${classroom.students.length} ${$t("students")}`}}</span>
					</div>
					<div v-if="classroomPerformance.length > 0">
						<span>{{$t('averageScore')}}:</span> <span>{{averageScore | round}}</span>
					</div>
				</div>
			</div>
			<tab :pages="pages" @tabChanged="tabChanged"
				:default="defaultTabPage">
				<div class="column">
					<div class="row"  v-if="displayExpandButton">
						<button @click="isReportExpanded = true" class="btn secondary buttoms">Expand</button>
						<button @click="exportReport" class="btn secondary buttoms">Export</button>
					</div>
					<transition name="fade">
						<component v-if="selectedPage" :is="selectedPage.component"/>
					</transition>
				</div>
			</tab>
			<modal v-if="isReportExpanded" @close="isReportExpanded = false" class="expanded-report"
				:showCancelButton="false" :click-handler="hideReport">

				<component v-if="selectedPage" :is="selectedPage.component"/>
			</modal>
		</div>
        <class-room-panel />
	</div>
</template>
<script>

import Datepicker from "vuejs-datepicker";
import StudentsList from "@/components/ClassRoomManagement/StudentsList.vue"
import ClassroomPerformance from "@/components/BackOffice/Reports/ClassroomPerformance/ClassroomPerformance.vue";
import StandardsReport from '@/components/ClassRoomManagement/StandardsReport.vue'
import AuthenticationService from "@/services/AuthenticationService.js"
import ClassRoomService from "@/services/ClassRoomService.js"
import { Role } from '@/utils/Roles.js'
import Tab from '@/common/Tab.vue'
import ClassRoomPanel from '@/components/ClassRoomManagement/ClassRoomPanel.vue'
import ProgramPreview from '@/components/ClassRoomManagement/ProgramPreview.vue'
import ScopeAndSequence from '@/components/ClassRoomManagement/ScopeAndSequence.vue'
import Modal from '@/common/Modal.vue'

export default {
	name: 'ClassRoomDetails',
	components:{
		"students-list": StudentsList,
		"classroom-performance": ClassroomPerformance, 
		"standards-report": StandardsReport, 
		"program": ProgramPreview,
		"scope-and-sequence": ScopeAndSequence,
		ClassRoomPanel,
		Datepicker, 
		Modal,
		Tab
	},
	data(){
		return{
			editMode: false, 
			originalName: '', 
			originalStartDate: '', 
			originalEndDate: '',
			endDate: '',
			startDate: '', 
            pages:[
                {name:'Students', component: 'students-list'}, 
                {name:'Class Performance', component: 'classroom-performance'}, 
                {name:'TEKS Report', component: 'standards-report'}, 
				{name:'Program', component: 'program'},
				{name:'Scope and Sequence', component: 'scope-and-sequence'}
			], 
			selectedPage: undefined, 
			isReportExpanded: false,
		}
	}, 
	computed:{
		teacherName(){
			if(!this.classroom)
				return
			let teacher = this.classroom.teachers[0]
			return  `${teacher.firstName} ${teacher.lastName}`
		}, 
		timePeriod(){
			return ` ${new Date(this.classroom.startDate).toLocaleDateString()} -
					${new Date(this.classroom.endDate).toLocaleDateString()}`
		}, 
		classroom(){
			return this.$store.state.selectedClassroom
		}, 
		nameChanged(){
			return this.originalName != this.classroom.name
		},
		name:{
			get(){
				return this.classroom.name
			}, 
			set(value){
				this.setComponentPropertyValue(value, 'name')
			}
		},
		currentSection(){
			return this.$store.state.classroomDetailsSection;
		},
		classroomPerformance(){
			return this.$store.state.classroomPerformance;
		},
		averageScore(){
			return this.classroomPerformance
						.reduce((accumulate, item) => accumulate + item.generalScore, 0)
						/ this.classroomPerformance.length;
		}, 
		StartDate:{
			get(){
				return this.startDate
			},
			set(value){
				this.startDate = value
				value = new Date(value).toISOString()
				this.setComponentPropertyValue(value, 'startDate')
			}
		},
		EndDate:{
			get(){
				return this.endDate
			},
			set(value){
				this.endDate = value
				value = new Date(value).toISOString()
				this.setComponentPropertyValue(value, 'endDate')
			}
		},
		validDates(){
			return new Date(this.endDate) > new Date(this.startDate)
		}, 
		defaultTabPage(){
			if(this.$route.query.program){
				this.$router.replace({
				...this.$router.currentRoute,
				query: { program: false}
				})
				return this.pages.find(e => e.component === 'program')
			}

			let user = AuthenticationService.getProfile()
			let component = user.roleName === Role.DistrictTeacher.name 
				? "students-list"
				: "standards-report"
			return this.pages.find(e => e.component === component)
		}, 
		displayExpandButton(){
			if(!this.selectedPage)
				return false
			return this.selectedPage.name === 'TEKS Report'
		}
	}, 
	methods:{
		updateClassroom(){
			if(!this.validDates){
				this.$store.commit('addSnackBarNotification',{
					message: 'Invalid dates range!'
				})
				return
			}
			this.$store.dispatch('updateClassroom', this.classroom)
				.then(()=>{
					this.editMode = false
					this.getOriginalValues()
				})
		},
		exportReport(){
			this.isLoadingInformation = true
			ClassRoomService.exportStandardsReport(this.classroom.id)
				.then((response)=> {
					const type = response.headers['content-type']
					const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
					const link = document.createElement('a')
					link.href = window.URL.createObjectURL(blob)
					link.download = 'TeksPerformance-'+ Date.now() + '.xlsx'
					link.click()
				})
				.finally(() => this.isLoadingInformation = false)
		},
		getOriginalValues(){
			this.originalName = this.classroom.name
			this.originalStartDate = this.classroom.startDate
			this.originalEndDate = this.classroom.endDate
			this.startDate = this.classroom.startDate
			this.endDate = this.classroom.endDate
		},
		restoreOriginalValues(){
			this.setComponentPropertyValue(this.originalName, 'name')
			this.setComponentPropertyValue(this.originalStartDate, 'startDate')
			this.setComponentPropertyValue(this.originalEndDate, 'endDate')
		},
		changeEditMode(){
			this.editMode = !this.editMode
			this.editMode
				? this.getOriginalValues()
				: this.restoreOriginalValues()
		},
        setComponentPropertyValue(value, propertyName){
            this.$store.commit('setBuilderComponentPropertyValue',
                    {component: this.classroom, propertyName: propertyName, value: value})
        }, 
        updateStartDate(e){
            e = new Date(e)
            this.setComponentPropertyValue(e, 'startDate')
        }, 
        updateEndDate(e){
			var date = new Date(e).toISOString();
            this.setComponentPropertyValue(date, 'endDate')
		}, 
		tabChanged(e){
			this.selectedPage = e
		}, 
		async loadClassrooms(){
			await this.$store.dispatch('loadClassRooms')
            .then(()=>{
                if(this.$route.query.classroom){
                   let selectedClassroom = this.$store.state.classrooms
                                    .find(e => e.id === this.$route.query.classroom)
                   this.$store.commit('updateSelectedClassroom', selectedClassroom)
                }
            })
		}, 
		hideReport(){
			this.isReportExpanded = false
		}
	},
	mounted() {
		if(!this.classroom){
			this.loadClassrooms()
		}
		this.getOriginalValues()
		this.$store.commit("setClassroomManagementSection", "classroomDetails");
		this.$store.commit("setClassRoomSubNavBarWithSectionSelect", false);
	},
    beforeDestroy(){
        this.$store.commit("setClassRoomSubNavBarWithSectionSelect", false);
    }
}
</script>
<style lang="less">
.classroom-details{
	width: 968px;
	margin: 0 auto;
	margin-top: 104px;
	position: relative;
	max-height: calc(100vh - 104px);
	>.column.custom-scrollbar{
		overflow-y: auto;
		padding-left: 10px;
		padding-right: 10px;
		padding-bottom: 40px;
		box-sizing: border-box;
		overflow-x: hidden;
	}
	.expanded-report{
		.modal-container{
			max-width: 90vw;
		}
		.report-container{
			min-width: 704px;
			width: auto;
			max-width: 85vw;
			max-height: 80vh;
		}
	}

	.tab{
		margin-top: 16px;
		min-height: unset;
		&-body{
			padding-bottom: 16px;
			box-sizing: border-box;
		}
		&-body > .column > .btn{
			margin-top: 8px;
			margin-left: 8px;
			max-height: 32px;
			max-width: 90px;
		}
	}

	.buttoms{
        margin-left: 10px;
	}

	.classroom-details-container{
		min-height: 196px;
		border-radius: 4px;
		background-color: #F6F7FA;
		box-shadow: var(--primary-shadow);
		padding: 16px;
		padding-bottom: 24px;
		box-sizing: border-box;
		margin-top: 16px;
		>.row{
			margin-top: 16px;
			div{
				width: 336px;
			}
		}

		.column{
			.legend{
				margin-top: 40px;
			}
		}

		.row:first-child{
			margin-top: 0;
			position: relative;
			.classroom-name{
				max-width: 420px;
				min-width: 420px;
				max-height: 30px;
				min-height: 36px;
			}
			.btn.secondary{
				margin-left: auto;
				margin-right: 16px;
			}
		}

		span:not(.legend){
			height: 28px;
			color: rgba(0,0,0,0.6);
			font-family: 'Roboto', sans-serif;
			font-size: 16px;
			letter-spacing: 0.5px;
			line-height: 28px;
			align-self: center;
			&:nth-child(even){
				margin-left: 16px;
				font-weight: 500;
			}
		}

		.editable-dates{
			margin-right: 46px;
			span{
				min-width: 10px;
			}
			span:first-of-type{
				min-width: 100px;
			}
			>*{
				margin-right: 16px;
			}
			.vdp-datepicker{
				min-width: 100px;
				max-width: 100px;
				max-height: 30px;
				min-height: 36px;
				input{
					
					min-width: 100px;
					max-width: 100px;
					max-height: 30px;
					min-height: 36px;
				}
			}
		}
	}
}
</style>



<template>
    <div class="student-item row" v-if="student">
        <span @click="goToTeacherFeedBack">{{student.firstName[0]}}{{student.lastName[0]}}</span>
        <span>{{student.firstName}} {{student.lastName}} ({{student.userName}})</span>
        <button class="btn secondary" @click="displayResetStudentPasswordModal">
                    {{$t("resetPasswordButtonText").toUpperCase()}}
        </button>
        <div class="column">
            <span>Text</span>
            <select v-model="studentLanguage">
                <option v-for="(language, index) in languages" :key="index"
                    :value="language.key">{{language.text.toUpperCase()}}</option>
            </select>
        </div>
        <div class="column">
            <span>Audio</span>
            <select v-model="audioLanguage">
                <option v-for="(language, index) in languages" :key="index"
                    :value="language.key">{{language.text.toUpperCase()}}</option>
            </select>
        </div>
        <img :src="deleteIcon" class="delete-icon" @click="showRemoveStudentModal = true"/>
        <modal v-if="showRemoveStudentModal" :click-handler="removeStudent"
				text-button="Remove"  @close="showRemoveStudentModal = false">
				<div class="modal-message row">
					<span>{{$t('removeStudentMessage')}}</span>
				</div>
		</modal>
        <modal v-if="showResetStudentPasswordModal"
            :clickOutSideModalHandler="hideModal"
            :showButtons="false">
            <reset-student-password :student="student"
                                    v-on:close="hideModal"/></modal>
    </div>
</template>
<script>
import Modal from '@/common/Modal.vue'
import ResetStudentPassword from "@/components/ClassRoomManagement/ResetStudentPassword.vue";
export default {
    name:'StudentItem', 
    components:{
        Modal,
        ResetStudentPassword
    },
    props: {
        student: Object, 
    }, 
    data(){
        return{
            showRemoveStudentModal: false,
            showResetStudentPasswordModal: false,
            languages:[
                {
                    key: 'en',
                    text: 'en'
                },
                {
                    key: 'es',
                    text: 'sp'
                }
            ]
        }
    },
    computed:{
		deleteIcon(){
            return require('@/assets/icons/ic_delete.svg')
        },
        studentLanguage:{
            get(){
                return this.student.textLanguage
            }, 
            set(value){
                this.$store.dispatch('changeStudentLanguage', {
                    student:this.student,
                    language:value})
            }
        },
        audioLanguage:{
            get(){
                return this.student.audioLanguage
            }, 
            set(value){
                this.$store.dispatch('changeStudentAudioLanguage', {
                    student:this.student,
                    language:value})
            }
        },

    },
    methods:{
        removeStudent(){
			this.$store.dispatch('removeStudentFromClassroom', {
				student: this.student, 
				classroom: this.$store.state.selectedClassroom
			})
        },
        goToTeacherFeedBack(){
            this.$router.push({
                name: 'StudentReports', 
                params:{
                    studentId: this.student.id
                }
            });
        },
        displayResetStudentPasswordModal(){
            this.showResetStudentPasswordModal = true;
        },
        hideModal(){
            this.showResetStudentPasswordModal = false;
        }
    }
}
</script>
<style lang="less" scoped>
.student-item{
    min-height: 56px;
    width: 100%;
    padding-right: 8px;
    align-content: center;
    cursor: pointer;
    .modal-message{
		height: 80px;
		span{
			margin: auto;
		}
    }

    >span,img,select{
        align-self: center;
        margin-left: 16px;
    };

    >span{
        color: rgba(0,0,0,0.6);
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        letter-spacing: 0.5px;
        line-height: 28px;
    }

    >span:first-child{
        min-height: 40px;
        min-width: 40px;
        border-radius: 50%;
        text-align: center;
        color: #fff;
        background-color: #3A4DFD;
        text-overflow: none;
        font-weight: 700;
        line-height: 40px;
        text-transform: uppercase;
        cursor: pointer;
    }
    .column{
        span{
            margin-left: auto;
            margin-right: auto;
        }
    }
    > .column:first-of-type{
        margin-right: 16px;
    }
    select{
        max-width: 80px;
        min-height: 30px;
    }

    button{
        height: 30px;
        width: 150px;
        align-self: center;
        margin-left: auto;
    }
}
</style>




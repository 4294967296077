import { render, staticRenderFns } from "./ClassroomPerformance.vue?vue&type=template&id=74e7c148&scoped=true&"
import script from "./ClassroomPerformance.vue?vue&type=script&lang=js&"
export * from "./ClassroomPerformance.vue?vue&type=script&lang=js&"
import style0 from "./ClassroomPerformance.vue?vue&type=style&index=0&id=74e7c148&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74e7c148",
  null
  
)

component.options.__file = "ClassroomPerformance.vue"
export default component.exports
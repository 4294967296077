<template>
    <div class="unit-preview column" :class="{'item-expanded' : !isCollapsed}" :style="{'height': height}">
        <div class="unit-details flex-container">
            <img :src="expandIcon" @click="expandContent">
            <span class="legend">{{$t('unit')}} {{unit.number}}</span>
            <span>{{unit.name}}</span>
        </div>
        <div class="column" v-if="!isCollapsed">
            <div class="content-list" v-if="unit.lessons" >
                <div class="list-item row"  v-for="lesson of unit.lessons"
                    :key="'lesson'+lesson.id + lesson.number" @click="previewLesson(lesson)">
                    <span>{{lesson.number}}</span>
                    <span>{{lesson.name|| $t('lesson')}}</span>
                </div>
            </div>
            <span class="other-content">{{$t('otherContentTag')}}</span>
            <div class="content-list">
                <div class="list-item row" v-for="biography of biographies"
                    :key="'biogrphy:'+biography.id"  @click="previewBiography(biography)">
                    <span>{{biography.name || 'Biography'}}</span>
                </div>
                <div class="list-item row" v-for="assessment of assessments"
                    :key="'assessment:'+assessment.id"  @click="previewAssessment(assessment)">
                    <span>{{$t('assessment')}} - {{$t('lesson')}} {{assessment.number}}</span>
                </div>
                <div v-if="unit.hasTimeLine" class="list-item row" @click="previewTimeline">
                    <span>{{$t('timeline')}}</span>
                </div>
                <div class="list-item row"
                    :key="'teacher page unit:' + unit.id"  @click="previewTeacherPage(unit)">
                    <span>{{$t('teacherPage')}} - {{$t('unit')}} {{unit.number}}</span>
                </div>
                <div class="list-item row" v-for="lesson of teacherPageLessons"
                    :key="'teacher page:'+lesson.id"  @click="previewTeacherPage(lesson)">
                    <span>{{$t('teacherPage')}} - {{$t('lesson')}} {{lesson.number}}</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'UnitPreview', 
    props:{
        unit: Object
    },
    computed:{
        isCollapsed(){
            return this.$store.state.unitPreviewExpanded === this.unit 
                ? false : true
        },
        chevronIcon(){
            return require('@/assets/icons/chevron_right.svg')
        },
        expandIcon(){
            if(this.isCollapsed)
                return require('@/assets/icons/expand_more.svg')
            else
                return require('@/assets/icons/expand_less.svg')
        }, 
        height(){
            return this.isCollapsed ? '48px' : `${150
                + (this.unit.biographies.length * 40) 
                +(this.unit.lessons.length * 40) 
                + (this.unit.hasTimeLine ? 40 : 0) 
                + ((this.unit.lessons.length -1) * 40)
                + (this.assessments.length * 40)}px`
        },
        lessons(){
            return this.unit.lessons
        },  
        biographies(){
            return this.unit.biographies
        }, 
        assessments(){
            return this.unit.lessons.filter(e => e.hasAssessment)
        },
        teacherPageLessons(){
            return this.lessons.filter((e, index) => index > 0)
        },
    }, 
    methods:{
        expandContent(){
            this.isCollapsed
                ? this.$store.commit('updateUnitPreviewExpanded', this.unit)
                : this.$store.commit('updateUnitPreviewExpanded', undefined)
        }, 
        previewLesson(lesson){
            if(lesson.number === 0){
                this.$router.push({
                name: 'OverviewBuilderPreviewMode', 
                params:{ unitId: this.unit.id}, 
                query:{ ...this.$route.query, }})
                return 
            }
            this.$router.push({
                name: 'LessonBuilderPreviewMode', 
                params:{
                    lessonId: lesson.id, 
                    unitId: this.unit.id
                }, 
                query:{                    
                    ...this.$route.query, 
                }
            })
        }, 
        previewBiography(biography){
            this.$router.push({
                name: 'BiographyBuilderPreviewMode', 
                params:{
                    biographyId: biography.id, 
                    unitId: this.unit.id
                }, 
                query:{                    
                    ...this.$route.query, 
                }
            })
        }, 
        previewAssessment(assessment){
            this.$router.push({
                name: 'AssessmentBuilderPreviewMode', 
                params:{
                    lessonId: assessment.id, 
                    unitId: this.unit.id
                }, 
                query:{...this.$route.query, }
            })
        }, 
        previewTimeline(){
            this.$router.push({
                name: 'TimeLineBuilderPreviewMode', 
                params:{
                    unitId: this.unit.id
                }, 
                query:{...this.$route.query, }
            })
        },
        previewTeacherPage(teacherPage){
            this.$router.push({
                name: 'TeacherPageBuilderPreviewMode', 
                params:{
                    teacherPageId: teacherPage.skillsId
                }, 
                query:{...this.$route.query, }
            })
        }
    }
}
</script>
<style lang="less" scoped>
 .unit-preview{
    margin: 8px auto;
	width: 100%;
	border-radius: 4px;
    background-color: #F6F7FA;
    box-shadow: var(--primary-shadow);
    transition: all 0.3s;
    .unit-details{
        align-items: center;
        width: 100%;
        height: 56px;
        >img{
            height: 16px;
            width: 16px;
            cursor: pointer;
        }
        img:nth-child(1){
            margin-left: 24px;
        }
        span:nth-child(2){
            margin-left: 32px;
            // min-width: 85px;
            
        }
        span:nth-child(3){
            height: 24px;
            margin-left: 16px;
            width: auto;
            max-width: 50%;
            color: #3A4DFD;
            font-family: Roboto;
            font-size: 16px;
            font-style: italic;
            font-weight: 500;
            letter-spacing: 0.15px;
            line-height: 24px;
        }
        img:nth-child(4){
            margin-left: 16px;
        }
        img:nth-child(5){
            margin-left: auto;
            margin-right: 16px;
            cursor: -webkit-grab;
        }
    }
    &.item-expanded{
        height: auto;
        border-radius: 4px;
        background-color: #FFFFFF;
        box-shadow: var(--secondary-shadow);
        flex-direction: column;
        padding-bottom: 22px;
        
    }
    .other-content{
        margin-top: 16px;
        margin-left: 24px;
        text-transform: uppercase;
        height: unset;
        font-weight: bold;
        letter-spacing: 2px;
    }

    .content-list{
        width: 703.94px;
        margin-top: 8px;
        cursor: pointer;
        &:first-of-type{
            span{
                span:nth-child(1){
                    font-family: 'Montserrat';
                    font-weight: bold;
                }
            }
        }
        .list-item{
            width: 100%;
            height: 40px;
            span{
                text-transform: capitalize;
                align-self: center;
                color: rgba(0,0,0,0.6);
                font-family: 'Roboto';
                font-size: 16px;
                letter-spacing: 0.5px;
                line-height: 28px;
            }
            span:nth-child(1){
                margin-left: 70px;
                min-width: 30px;
            }
            span:nth-child(2){
                margin-left: 20px;
                max-width: 550px;
            }

            &:hover{
                background-color: rgba(0,0,0,0.05);

            }
        }
    }
    
    .content-list:first-of-type{
        span{
            span:nth-child(1){
                font-family: 'Montserrat';
                font-weight: bold;
            }

        }
    }
 }
</style>


